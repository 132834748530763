import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { AssetCacheService } from 'src/app/services/asset-cache.service';
import { AssetService, Crumb } from 'src/app/services/asset.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user.service';
import { DTO } from 'src/dto/dto';

const LOGIN_HASH_TIMEOUT = 5 * 60 * 1000;

export class RoutePath {
    firstSegment: string;
    restSegments: string[];
    parent?: RoutePath;

    /**
     * These properties are injected inside of the lazy-loaded component
     *
     * If not using the lazy loader, the component acting as the portal
     * is responsible for properly injecting these into the child component
     *
     */
    properties: {
        urlParams: Object,
        childSegments: string[],
        asset:  DTO,
        trail:  Crumb[],
        $scope?: string,
        dto: string,
        /**
         * This is a cyclic reference for the parent RoutePath that is
         * automatically injected via loaders to the @Input() property
         */
        routePath: RoutePath
    };

    constructor(props: Partial<RoutePath>) {
        Object.entries(props).forEach(([k, v]) => this[k] = v);
    }

    next() {
        const childSegments = this.properties.childSegments.slice(1);
        const data: RoutePath['properties'] = {
            ...this.properties.urlParams,
            urlParams: this.properties.urlParams,
            childSegments: childSegments || [],
            asset:  this.properties.asset,
            trail:  this.properties.trail,
            $scope: this.properties.$scope,
            dto:    this.properties.asset?.dto,
            routePath: null
        };
        const routePath = new RoutePath({
            firstSegment: this.properties.childSegments[0],
            restSegments: childSegments || [],
            properties: data,
            parent: this
        });

        data.routePath = routePath;

        return routePath;
    }

    /**
     * Create a new RoutePath object within the current context for a path
     */
    fork(path: string, args?: Object) {

        let [hash, query] = path.split('?');



        // If the hash is relative, evaluate the hash into an absolute path.
        if (!hash.startsWith("#/")) {
            // TODO
            // Relative path handling
            // const relPath =
            hash = "#/" + '' + hash;
        }

        // Now we have an absolute path to work on.
        const segments = hash.split('/').slice(1);
        const ancestors = this.getAncestorPaths();
        const ancestorSegments = ancestors.map(a => a.firstSegment);

        // If all of the segments match, we will cut off the matching segments
        const forceAbsoluteNav = !ancestorSegments.every((s, i) => segments[i] == s);

        const childSegments = forceAbsoluteNav ? segments : segments.slice(ancestorSegments.length);

        const data: RoutePath['properties'] = {
            ...this.properties.urlParams,
            ...queryStringToJSON(query ?? ''),
            ...(args || {}),
            urlParams: this.properties.urlParams,
            childSegments: childSegments.slice(1) || [],
            asset: this.properties.asset,
            $scope: '',
            trail: this.properties.trail,
            dto: this.properties.asset?.dto,
            routePath: null
        };
        const routePath = new RoutePath({
            firstSegment: childSegments[0],
            restSegments: childSegments.slice(1) || [],
            properties: data,
            parent: this
        });

        data.routePath = routePath;

        return routePath;
    }

    private getAncestorPaths() {
        let parent = this.parent as RoutePath;
        let i = 0;
        const parents = [] as RoutePath[];

        // Look up all the ancestor rootPaths
        while (parent && i++ < 100) {
            parents.unshift(parent);
            parent = parent.parent;
        }
        return parents;
    }
};

@Injectable({
    providedIn: 'root'
})
export class RouterService {

    public rootPath$ = new BehaviorSubject<RoutePath>(null);

    public get urlData() {
        return this.rootPath$.value?.properties?.['urlParams'];
    }

    private previousParameters;

    constructor(
        private readonly assetService: AssetService,
        private readonly assetCache: AssetCacheService,
        private readonly tenant: TenantService,
        private readonly user: UserService
    ) {
        window.router = this;
        tenant.subscribe(t => t && this.loadRootRoutePath());

        // Update the currently selected root page
        // window.history pushState and replaceState do not trigger this!
        window.addEventListener('hashchange', () => this.loadRootRoutePath());

        // Disabling for now, click handling is faulty with nested links.
        // It works well enough without a custom click handler, so we'll be fine for now.
        // window.addEventListener('click', (e) => this.handleClicks(e));
    }

    /**
     * Make a best-effort attempt at loading the current top-level route
     * will failsafe back to a 404 screen?
     */
    async loadRootRoutePath() {
        const originalNavTarget = this.getOriginalNavTarget();

        // Restore the original login hash via the common route
        if (originalNavTarget) {
            location.hash = originalNavTarget;
            return;
        }

        const [hash, urlParams] = location.hash.split("?");
        let urlData = queryStringToJSON(urlParams ?? '');

        // If a change of tenant occured, re-load the user profile and clear the params.
        if (this.previousParameters?.['es-tenant'] != urlData?.['es-tenant']) {
            window['tenantselector']?.selectTenant(urlData['es-tenant']);
        }

        this.previousParameters = urlData;
        const urlParamsProxy = createUrlProxy(urlData);

        // Strip any portion of the starting url hash
        // e.g. `/Page` `#Page` `/#/Page` `/#Page` `#/Page` => `Page`
        let [root, ...childSegments] = hash.toLowerCase().replace(/^\/?#?\/?/, '').split('/');

        // If the root is simply empty, load the landing page.
        if (!root.trim()) {
            root = "Landing"
        }

        // If the asset was changed, we will first have the asset service
        // load the new root scope, _then_ we emit the next root-level route segment.
        // TODO: This test needs to take into account different variants of the notation!
        const rootAsset = this.assetService.rootAsset;
        const assetIdentifier = urlData['$scope']?.split("/").pop();
        if (assetIdentifier && assetIdentifier != (rootAsset?.dto + '.' + rootAsset?.id)) {
            // We set the scope, and then the proxy updates the URL accordingly.
            urlParamsProxy['$scope'] = await this.assetService.setRootScope(urlData['$scope']);
        }

        // If $open is specified on the URL, remove it and open the corresponding dialog.
        const open = urlData['$open'];
        if (open) {
            delete urlParamsProxy['$open'];

            this.assetCache.getAsset(open).then(asset => {
                this.assetService.openAssetPropertiesDialog(asset);
            });
        }

        // Build a new segment that has cyclic references
        const routePath = new RoutePath({
            firstSegment: root,
            restSegments: childSegments || [],
            properties: {
                ...urlData,
                urlParams: urlParamsProxy,
                childSegments: childSegments || [],
                asset: this.assetService.rootAsset,
                trail: this.assetService.rootTrail,
                dto:   this.assetService.rootScope?.dto, // TODO This here - it would be nice to have the criteria
                routePath: null
            }
        });

        // Passing around route segments needs to be able to reference the original when
        // getting the immediate next segment.
        routePath.properties.routePath = routePath;
        this.rootPath$.next(routePath);
    }

    public updateUrlParam(name, value) {
        const [hash, urlParams] = location.hash.split("?");
        const urlData = queryStringToJSON(urlParams ?? '');
        const urlParamsProxy = createUrlProxy(urlData);
        urlParamsProxy[name] = value;
    }

    private getAncestry(element: HTMLElement) {
        const pathAncestors: HTMLElement[] = [];

        let parent = element?.parentElement;
        do {
            pathAncestors.push(parent);
            parent = parent?.parentElement;
        }
        while (parent && parent != document.body);

        return pathAncestors.filter(a => !!a);
    }

    private handleClicks(e: MouseEvent) {
        const ancestry = this.getAncestry(e.target as any);
        const links = ancestry.filter(a => a.nodeName == "A") as HTMLAnchorElement[];
        const [link] = links;

        // Ensure the anchor at least has a defined href.
        if (!link || !link.href) return;
        const { href, target } = link;

        // Find all local targets, and attempt to intercept loading them
        if ([null, '_self', ''].includes(target)) {
            e.preventDefault();
            e.stopPropagation();

            // TODO: Should we restrict navigation by link format?

            const data = queryStringToJSON(href.split('?')[1] ?? '');
            history.replaceState(data, null, href);
            this.loadRootRoutePath();
        }
    }

    /**
     * Navigate the page to the specified path with query parameters
     * or optionally pass parameters as a data object
     */
    public loadRouteString(path: string, params?: Object) {
        if (params) {
            const paramsStr = JSONToQueryString(params);
            path = path + (path.includes("?") ? '&' : '?') + paramsStr;
        }

        // TODO: Can we do this without an anchor element?
        let a = document.createElement("a");
        a.href = path;
        a.click();
        a.remove();
    }

    /**
     * Utility to replace the path in the address bar without materially impacting generated links
     *
     * TODO: handle in such a manner that previously generated links can be aware that there may have
     * been a silent virtual path change.
     */
    public replaceRoutePath(path: string) {
        const url = path + (location.hash.includes('?') ? '?' : '') + location.hash.split('?')?.[1];

        history.replaceState(null, null, url);
    }


    /**
     * Replace the route path in the address bar. Bart is responsible for all gremlins.
     */
    public updateRoutePath(routePath: RoutePath, pathSeg: string) {

        const path = [pathSeg];
        let parent = routePath.parent;
        let i = 0;

        while (parent && i++ < 100) {
            path.unshift(parent.firstSegment);
            parent = parent.parent;
        }

        const hash = "#/" + path.join('/');
        const [oldHash, query] = location.hash.split('?');

        const url = hash + "?" + query;

        // If the current URL is more precise compared to
        // the target hash or the value is unchanged,
        // then exit without performing an update
        if (oldHash.startsWith(hash)) return;

        history.replaceState(null, null, url);

        this.loadRootRoutePath();
    }

    /**
     * Replace the current scope in the address bar
     */
    public replaceScope(scope: string | DTO | DTO[]) {
        // Coerce the scope to always be a normalized string.
        if (Array.isArray(scope)) {
            scope = scope.reduce((a, b) => a + '/' + (b.dto + '.' + b.id), '');
        }
        else if (typeof scope == "object") {
            scope = scope.dto + '.' + scope.id;
        }

        const [hash, query] = location.hash.split("?");

        const urlData = queryStringToJSON(query ?? '');
        urlData['$scope'] = scope;
        const queryString = JSONToQueryString(urlData);

        const url = hash + "?" + queryString;

        history.replaceState(null, null, url);

        this.loadRootRoutePath();
    }

    /**
     * Utility to return the virtual path up to a provided context element
     */
    public getElementVirtualPath(context: HTMLElement, link?: string) {
        // If the link is absolute, ignore any path interpretation
        if (link?.startsWith('#/')) {
            return link;
        }

        const ancestors = this.getElementAncestry(context);
        const pathAncestors = ancestors.map(a => a?.getAttribute("data-route-segment")).filter(a => !!a);

        return "#/" + pathAncestors.join("/") + '/' + (link || '');
    }

    /**
     * Utility to create a link for a root asset under a provided context element.
     * It is intentional to be able to generate links that do not specify a root asset.
     */
    public createRootAssetLink(context: HTMLElement, asset?: DTO, link?: string, params: Object = {}) {
        const pars = structuredClone(params);
        const path = this.getElementVirtualPath(context, link);

        // TODO: Read the rest of the scope!
        if (asset) {
            if (asset.id) {
                pars['$scope'] = asset.dto + '.' + asset.id;
            }
            else {
                pars['$scope'] = asset.dto;
            }
        }
        pars['es-tenant'] = this.tenant.value.es_tenant_name;

        return this.createUrlFragment(path, pars);
    }

    /**
     * Utility to focus a new root asset provided a context element
     */
    public focusNewRootAsset(context: HTMLElement, asset?: DTO) {
        const link = this.createRootAssetLink(context, asset);
        this.loadRouteString(link);
    }

    /**
     *
     */
    public createUrlFragment(path: string, params?: Object) {
        if (!path.startsWith("#/")) {
            path = "#/" + path;
        }
        // Collapse all extra forward slashes
        path = path.replace(/\/\/+/g, '/');

        if (params) {
            const paramsStr = JSONToQueryString(params);
            path = path + (path.includes("?") ? '&' : '?') + paramsStr;
        }
        return path;
    }

    /**
     * During the login flow, store the url hash in sessionStorage.
     */
    public setOriginalLoginHash() {
        sessionStorage['$elevate-hash'] = location.hash;
        sessionStorage['$elevate-hash-date'] = new Date().getTime();
    }

    /**
     * During the login flow, we will check if there was a previous
     * url hash recorded in session storage.
     */
    private getOriginalNavTarget() {
        const hash = sessionStorage['$elevate-hash'];
        const hashDate = sessionStorage['$elevate-hash-date'];
        delete sessionStorage['$elevate-hash'];
        delete sessionStorage['$elevate-hash-date'];

        // If a login completed within 5 minutes, we restore the user's last page from their session.
        const timeSinceLogin = new Date().getTime() - parseInt(hashDate);
        if (
            hash &&
            hashDate &&
            timeSinceLogin < LOGIN_HASH_TIMEOUT
        ) {
            return hash;
        }

        return null;
    }

    private getElementAncestry(element: HTMLElement) {
        const pathAncestors: HTMLElement[] = [];

        let parent = element;
        do {
            pathAncestors.unshift(parent);
            parent = parent?.parentElement;
        }
        while (parent && parent != document.body);

        return pathAncestors;
    };
}

/**
* Create a proxied object that updates the URL when any value is updated
*
* ! Note: this pukes if you set a key to null.
*/
const createUrlProxy = (data: Object) =>
    new Proxy(data, {
        set(target: object, key, newValue) {
            target[key] = newValue;

            const q = JSONToQueryString(data);
            const [hash] = location.hash.split('?');
            history.replaceState(data, null, hash + '?' + q);

            return true;
        },
        deleteProperty(target, p) {
            this.set(target, p, null);

            return true;
        }
    });


/**
 * Make a `best-effort` attempt to serialize a query string into a json object.
 * Will attempt to restore numbers, booleans, Dates, Arrays and Objects.
 */
export const queryStringToJSON = (qs: string) => {
    qs ??= '';
    const pairs = qs.split('&');
    const result = {};

    pairs.forEach(p => {
        const pair = p.split('=');
        const key = decodeURIComponent(pair[0] || '');
        const value = decodeURIComponent(pair[1] || '');
        const parsedValue = parseValue(value);

        // If there is a previous result with the same name
        // e.g. test=foo&test=bar => ['foo', 'bar']

        if (result.hasOwnProperty(key)) {
            if (Array.isArray(result[key])) {
                result[key].push(parsedValue);
            }
            else {
                result[key] = [result[key], parsedValue];
            }
        }
        else {
            result[key] = parsedValue
        }
    });

    return result;
};

/**
 * Make a `best-effort` attempt to serialize a json object into a query string.
 * Will attempt to store numbers, booleans, Dates, Arrays and Objects.
 */
export const JSONToQueryString = (json: Object) => {
    let result = '';
    Object.entries(json).forEach(([key, value], i) => {
        if (
            typeof value == "function" ||
            typeof value == "undefined" ||
            typeof value == "symbol" ||
            value === null
        ) {
            // These types cannot be serialized
            return;
        }

        let val: string;
        // If it's an object, try to write it into a string
        if (typeof value == "object") {
            if (value instanceof Date) {
                val = value.toISOString();
            }
            else {
                try {
                    val = encodeURIComponent(JSON.stringify(value));
                }
                catch(ex) {
                    val = null;
                }
            }
        }
        // Anything else can just be ignored
        else {
            val = value.toString();
        }

        if (val || val == '0') {
            result += result.length > 0 ? '&' : '';
            result += key + '=' + val;
        }
    });

    return result;
};

const parseValue = (value) => {
    // parse whole numbers (ignore commas)
    if (/^[\d,]+$/.test(value)) {
        return parseInt(value);
    }

    // parse hex numbers
    else if (/^0x\d+$/.test(value)) {
        return parseInt(value);
    }

    // parse floats
    else if (/^[\d,]*\.[\d,]+$/.test(value)) {
        return parseFloat(value);
    }

    // Detect if it's true/false
    else if (/^(?:true|false|yes|no)$/i.test(value)) {
        return /true|yes/i.test(value);
    }

    // Detect if it's a json string
    else if (/^(?:[\[].+?[\]]|[\{].+?[\}])$/.test(value)) {
        try {
            return JSON.parse(value, (key, jv) => {
                // If the value is a string, check if it's a valid date
                // if (typeof jv == 'string') {
                    // if (!Number.isNaN(new Date(jv).getTime())) {
                    //     return new Date(jv);
                    // }
                // }
                return jv;
            });
        }
        catch (err) {
            // Not a valid json element -- it's a string
            return value;
        }
    }

    // Detect if it's a valid date
    // else if (!Number.isNaN(new Date(value).getTime())) {
    //     return new Date(value);
    // }

    // All else fails, this is just a string :)
    else {
        return value;
    }
};
